import React from "react";

// Root Include
const Insurance = React.lazy(() => import("./pages/Insurance/index"));
const PageAboutUs = React.lazy(() => import("./pages/Insurance/PageAboutUs"));
const PageContactTwo = React.lazy(() =>
  import("./pages/Insurance/PageContactTwo")
);
const Members = React.lazy(() => import('./pages/Insurance/membersForm'));
const routes = [
    //routes without Layout
    { path: "/", component: Insurance, isTopbarDark: true },
    { path: "/index", component: Insurance, isTopbarDark: true },
    { path: "/page-aboutus", component: PageAboutUs, isTopbarDark: true },
    { path: "/page-contact-two", component: PageContactTwo, isTopbarDark: true },
    { path: "/members", component: Members },
    // { path: "/index-hospital", component: Hospital, isTopbarDark: true },
];

export default routes;